import React from "react";

import { apiFetch } from "../../../../../../apiBindings";
import pushToAnalytics from "../../../../../../utils/analytics";
import StorageHandler from "../../../../../../utils/StorageHandler";
import Columns from "../../../../Columns";
import Checkbox from "../../../../forms/Checkbox";
import FormError from "../../../../forms/FormError";
import NewInput from "../../../../forms/NewInput";
import LoadingIndicator from "../../../../LoadingIndicator";
import Margin from "../../../../Margin";
import Button from "../../../../NewForms/Button";
import NewHeadline from "../../../../NewHeadline";
import { UncontrolledNotification as Notification } from "../../../../Notification";
import useStore from "./store";

const storage = new StorageHandler();

const ServiceHotline = ({ name = "service-hotline" }) => {
    const {
        formData,
        onChange,
        addFormError,
        clearError,
        hasErrorAt,
        setErrors,
        errors,
        sending,
        setSending,
        sendingError,
        setSendingError,
        emailSent,
        setEmailSent,
        privacy,
        setPrivacy,
    } = useStore();

    const onSubmit = (event) => {
        event.preventDefault();

        // Check if the fields are valid
        const localErrors = [];
        Object.keys(formData).map((item) => {
            if (
                (formData[item] === "" && item !== "salutation") ||
                formData[item] === false
            ) {
                localErrors.push(item);
            }
        });
        if (privacy === false) {
            localErrors.push("privacy");
        }

        if (localErrors.length > 0) {
            setErrors(localErrors);
            return;
        }

        if (errors.length === 0) {
            setSending(true);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            void apiFetch("/contact/serviceHotline", {
                method: "post",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: JSON.stringify(formData),
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.success) {
                        storage.set(`${name}:contact-form:sent-at`, Date.now());
                        storage.set("app:uid", responseData.appUid);
                        setEmailSent(true);
                        setSendingError(false);
                        pushToAnalytics("contact-form:sent", {
                            form: `${name}:contact-form`,
                        });
                    } else {
                        setSendingError(true);
                    }
                    setSending(false);
                });
        }
    };

    return (
        <>
            <Columns>
                <Columns.Column>
                    <NewHeadline>Deine Nachricht an uns:</NewHeadline>
                    {sendingError ? (
                        <Notification>
                            <NewHeadline>Entschulding</NewHeadline>
                            <div>
                                Es ist leider ein Fehler aufgetreten. Versuche
                                es bitte erneut.
                            </div>
                            <Margin
                                Component={Button}
                                top="20"
                                fullWidth
                                onClick={() => setSendingError(false)}
                            ></Margin>
                        </Notification>
                    ) : sending ? (
                        <Notification>
                            <LoadingIndicator>
                                Deine Nachricht wird übermittelt. Bitte habe
                                einen Moment Geduld.
                            </LoadingIndicator>
                        </Notification>
                    ) : emailSent ? (
                        <Notification>
                            <NewHeadline>
                                Vielen Dank für Deine Anfrage
                            </NewHeadline>
                            <div>
                                Wir haben Deine Anfrage erhalten und melden uns
                                in Kürze bei Dir.
                            </div>
                        </Notification>
                    ) : (
                        <form onSubmit={onSubmit}>
                            <Columns>
                                <Columns.Column>
                                    <div className="field">
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select
                                                    name="salutation"
                                                    onChange={onChange}
                                                    value={formData.salutation}
                                                >
                                                    <option value="Herr">
                                                        Herr
                                                    </option>
                                                    <option value="Frau">
                                                        Frau
                                                    </option>
                                                    <option value="Divers">
                                                        Divers
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <NewInput
                                        name="firstname"
                                        value={formData.firstname}
                                        onChange={onChange}
                                        placeholder="Vorname"
                                        validation="name"
                                        required
                                        onError={() =>
                                            addFormError("firstname")
                                        }
                                        onErrorSolved={() =>
                                            clearError("firstname")
                                        }
                                        forceError={hasErrorAt("firstname")}
                                    />

                                    <NewInput
                                        name="lastname"
                                        value={formData.lastname}
                                        onChange={onChange}
                                        placeholder="Nachname"
                                        validation="name"
                                        required
                                        onError={() => addFormError("lastname")}
                                        onErrorSolved={() =>
                                            clearError("lastname")
                                        }
                                        forceError={hasErrorAt("lastname")}
                                    />

                                    <Margin
                                        top="30"
                                        Component={NewInput}
                                        name="email"
                                        value={formData.email}
                                        onChange={onChange}
                                        placeholder="E-Mail"
                                        validation="email"
                                        required
                                        onError={() => addFormError("email")}
                                        onErrorSolved={() =>
                                            clearError("email")
                                        }
                                        forceError={hasErrorAt("email")}
                                    />
                                    <NewInput
                                        name="phone"
                                        value={formData.phone}
                                        onChange={onChange}
                                        placeholder="Telefon"
                                        validation="phone"
                                        required
                                        onError={() => addFormError("phone")}
                                        onErrorSolved={() =>
                                            clearError("phone")
                                        }
                                        forceError={hasErrorAt("phone")}
                                    />
                                </Columns.Column>
                                <Columns.Column>
                                    <Margin
                                        Component={NewInput}
                                        top="30"
                                        name="message"
                                        type="textarea"
                                        placeholder="Deine Nachricht"
                                        required
                                        value={formData.message}
                                        onChange={onChange}
                                        validation="message"
                                        forceError={hasErrorAt("message")}
                                        fullHeight
                                    />
                                </Columns.Column>
                            </Columns>

                            <Columns>
                                <Columns.Column>
                                    <Checkbox
                                        checked={privacy}
                                        onClick={() => {
                                            if (!privacy) {
                                                clearError("privacy");
                                            } else {
                                                addFormError("privacy");
                                            }
                                            setPrivacy(!privacy);
                                        }}
                                        label={
                                            <span>
                                                Ich habe die{" "}
                                                <a
                                                    href="/datenschutz"
                                                    target="blank"
                                                >
                                                    Datenschutzerklärung
                                                </a>{" "}
                                                zur Kenntnis genommen.
                                            </span>
                                        }
                                    />
                                    {hasErrorAt("privacy") && (
                                        <FormError message="Akzeptieren Sie bitte die Datenschutzerklärung." />
                                    )}
                                </Columns.Column>
                                <Columns.Column>
                                    <Button
                                        fullWidth
                                        data-gtag={`${name}:contact-form`}
                                        type="submit"
                                    >
                                        Anfrage Senden
                                    </Button>
                                </Columns.Column>
                            </Columns>
                        </form>
                    )}
                </Columns.Column>
            </Columns>
        </>
    );
};

export default ServiceHotline;
